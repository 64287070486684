import GATSBY_COMPILED_MDX from "C:/Users/ribbe/WebstormProjects/rebel_thorp_umbrella/client/app/src/content/guides/animation/index.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import {MDXProvider} from '@mdx-js/react';
import {LABEL, POSITION} from '@core/constants';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import Hero from '@components/Hero';
import StickyFilter from '@components/StickyFilter';
import Centerer from '@components/Centerer';
import Sidebar from '@components/Sidebar';
import Content from '@components/Content';
import Link from '@components/Link';
import Message from '@components/Message';
import TableOfContents from '@components/mdx/TableOfContents';
import ListIcon from '@assets/list.svg';
const components = {
  Link,
  Message
};
function GuideTemplate({data, children, pageContext}) {
  const {frontmatter, tableOfContents} = data.mdx;
  const heroImage = getImage(data.heroImage);
  const [isActive, setIsActive] = React.useState(false);
  const handleFloatingSidebarOpen = () => setIsActive(true);
  const handleFloatingSidebarClose = () => setIsActive(false);
  return React.createElement(Main, null, React.createElement(Hero, {
    title: LABEL.GUIDES,
    description: frontmatter.title,
    image: heroImage
  }), React.createElement(StickyFilter, {
    items: pageContext.categories
  }), React.createElement(Centerer, {
    tagName: "section",
    withVerticalPadding: true,
    sidebar: POSITION.LEFT
  }, React.createElement(Sidebar, {
    sticky: true,
    floating: true,
    floatingLabel: "Table of contents",
    floatingIcon: React.createElement(ListIcon, {
      width: 24,
      height: 24
    }),
    withFilter: true,
    isActive: isActive,
    onRequestOpen: handleFloatingSidebarOpen,
    onRequestClose: handleFloatingSidebarClose
  }, React.createElement(TableOfContents, {
    items: tableOfContents.items,
    onClick: handleFloatingSidebarClose
  })), React.createElement(Content, {
    className: "format"
  }, React.createElement(MDXProvider, {
    components: components
  }, children))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(GuideTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export function Head({data}) {
  const {fields, frontmatter} = data.mdx;
  return React.createElement(BaseHead, {
    title: fields.slug !== 'guides' ? [frontmatter.title, LABEL.GUIDES] : LABEL.GUIDES
  });
}
export const query = graphql`
    query ($slug: String!, $image: String!) {
        mdx(fields: { source: { eq: "guides"}, slug: { eq: $slug } }) {
            id
            fields {
                slug
            }
            frontmatter {
                title
            }
            tableOfContents
        }
        heroImage: file(
            absolutePath: { glob: $image }
        ) {
            ...BackgroundImage
        }
    }
`;
